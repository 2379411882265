@tailwind base;

@tailwind components;

@import "./base.scss";

@tailwind utilities;

@import "./mixins.scss";

@import "./custom.scss";

@import "./lexical-editor.scss";
