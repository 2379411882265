@import "./variables.scss";

html,
body {
  padding: 0;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  font-size: 15px;

  @media screen and #{$mq-mobile} {
    overflow-x: hidden;
  }
}

a {
  color: inherit;
  text-decoration: none;

  &:hover {
    color: $smart-teal;
  }
}

input,
textarea {
  appearance: none;
  border-width: 1px;
  border-style: solid;
  border-color: #ddd;
  border-radius: 2px;
  transition: border-color;
  font-size: 1em;
  padding: 0.5em;
  box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.06);

  &:focus {
    border-color: #24a9a7;
    box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.06),
      0 0 5px rgba(32, 148, 146, 0.7);
    outline: 0;
  }
}

* {
  box-sizing: border-box;
}

.tooltip {
  z-index: 2000;
}

#credential_picker_container {
  top: 60px !important;
  right: 0 !important;
}

.position-unset {
  position: unset !important;
}

.yarl__button {
  filter: none !important;
}

// google translate element

#google_translate_element {
  display: none;
}

.skiptranslate {
  // position: absolute;
  // bottom: 0;
  iframe {
    display: none;
  }
}

#goog-gt-tt {
  display: none !important;
}

body {
  top: 0 !important;
}

// end google translate element
