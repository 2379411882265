.modal {
  z-index: 1999;

  .overlay {
    z-index: 1999;
    position: fixed;
    inset: 0px;
    background-color: rgba(255, 255, 255, 0.75);

    .content {
      background-color: #fff;
      height: 100%;

      .actionsBar {
        position: absolute;
        max-width: 1378px;
        margin: 0 auto;
        left: 0;
        right: 0;

        .closeButton {
          font-size: 25px;
          float: right;
          margin-right: 27.5px;
          margin-top: 15px;
          cursor: pointer;
        }
      }

      .iframe {
        width: 100%;
        height: 100%;
      }
    }
  }
}
