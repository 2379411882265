@use "sass:math";

@import "./variables.scss";

@mixin link-color(
  $customBaseColor: null,
  $customHoverColor: null,
  $customActiveColor: null
) {
  $hoverColor: if(
    $customHoverColor,
    $customHoverColor,
    if($customBaseColor, $customBaseColor, $hover-link-color)
  );
  $activeColor: if(
    $customActiveColor,
    $customActiveColor,
    if($customBaseColor, $customBaseColor, $hover-link-color)
  );
  $baseColor: if($customBaseColor, $customBaseColor, $base-link-color);
  color: $baseColor;

  &:hover,
  &:focus {
    color: $hoverColor;
  }

  &:focus:hover,
  &:active {
    color: $activeColor;
  }
}

@mixin invert-button-color($primaryColor: $smart-teal, $secondaryColor: white) {
  color: $primaryColor;
  background-color: $secondaryColor;
  border-color: $primaryColor;

  &:hover,
  &:focus {
    color: $secondaryColor;
    background-color: $primaryColor;
  }
}

@mixin invert-button-color-chart(
  $primaryColor: $smart-teal,
  $secondaryColor: white
) {
  background-color: $secondaryColor;
  border-color: #ececec;

  &:hover,
  &:focus,
  &:active {
    color: $secondaryColor;
    background-color: $primaryColor;
  }
}

@mixin link-bottomline(
  $baseColor,
  $customHoverColor: null,
  $customActiveColor: null
) {
  $normalColor: if(
    $customHoverColor,
    $baseColor,
    transparentize($baseColor, 0.5)
  );
  $hoverColor: if($customHoverColor, $customHoverColor, $baseColor);
  $activeColor: if($customActiveColor, $customActiveColor, $baseColor);
  border-bottom: 1px solid $normalColor;

  .no-touch &:hover {
    border-bottom-color: $hoverColor;
  }

  &:focus,
  &:active {
    border-bottom-color: $activeColor;
  }
}

@mixin break-inside($value) {
  break-inside: $value;
  -webkit-column-break-inside: $value;
  page-break-inside: $value;
}

@mixin card-shadow {
  border-radius: $card-border-radius;
  box-shadow: $card-shadow;
}

@mixin desktop-max-width {
  @media screen and (min-width: #{$desktop-max-width}) {
    margin-left: calc(#{$desktop-max-width} / 2 - 50vw);
    width: 100vw;

    > div {
      margin: 0 auto;
      max-width: $desktop-max-width;
    }
  }
}

@mixin block-ellipsis($lines: 2) {
  // prevent this rule removed by autoprefixer
  // see: https://github.com/postcss/autoprefixer/issues/776

  /*! autoprefixer: off */
  -webkit-box-orient: vertical;
  display: -webkit-box;
  -webkit-line-clamp: $lines;
  overflow: hidden;
  text-overflow: ellipsis;
}

@mixin fab-button {
  @include size(44px);
  align-items: center;
  background: #fff;
  border-radius: 50%;
  box-shadow: $card-shadow;
  box-sizing: border-box;
  color: $subtle-black;
  display: flex;
  font-size: $fontsize-xs;
  justify-content: center;
  position: relative;
  transition: 0.2s ease-in-out;

  &:hover {
    background: $shade-hover;
    color: $subtle-black;
  }

  &.\--compact {
    &:hover {
      background: #fff;
    }
  }
}

@mixin scrollbars(
  $size: 5px,
  $foreground-color: $shady-gray,
  $background-color: transparent
) {
  // For Google Chrome
  &::-webkit-scrollbar {
    height: $size;
    width: $size;
  }

  &::-webkit-scrollbar-thumb {
    background: rgba($foreground-color, 0);
    border-radius: 5px;
  }

  &:hover {
    &::-webkit-scrollbar-thumb {
      background: rgba($foreground-color, 1);
    }
  }

  &::-webkit-scrollbar-track {
    background: $background-color;
  }

  // For Internet Explorer
  body {
    scrollbar-face-color: $foreground-color;
    scrollbar-track-color: $background-color;
  }
}

@mixin pulse($color, $pulse-name) {
  $pulseName: $pulse-name + "Pulse";
  animation: #{$pulseName} 2s infinite;

  @keyframes #{$pulseName} {
    0% {
      box-shadow: 0 0 0 0 rgba($color, 1);
    }

    70% {
      box-shadow: 0 0 0 6px rgba($color, 0);
    }

    75% {
      box-shadow: 0 0 0 0 rgba($color, 0);
    }
  }
}

// https://css-tricks.com/snippets/css/shake-css-keyframe-animation/
@mixin shake() {
  animation: shake 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
  backface-visibility: hidden;
  perspective: 1000px;
  transform: translate3d(0, 0, 0);

  @keyframes shake {
    10%,
    90% {
      transform: translate3d(-1px, 0, 0);
    }

    20%,
    80% {
      transform: translate3d(2px, 0, 0);
    }

    30%,
    50%,
    70% {
      transform: translate3d(-4px, 0, 0);
    }

    40%,
    60% {
      transform: translate3d(4px, 0, 0);
    }
  }
}

// @author: https://gist.github.com/terkel/4373420
@function decimal-round($number, $digits: 0, $mode: round) {
  $n: 1;
  // $number must be a number
  @if type-of($number) != number {
    @warn '#{ $number } is not a number.';

    @return $number;
  }
  // $digits must be a unitless number
  @if type-of($digits) != number {
    @warn '#{ $digits } is not a number.';

    @return $number;
  } @else if not unitless($digits) {
    @warn '#{ $digits } has a unit.';

    @return $number;
  }

  @for $i from 1 through $digits {
    $n: $n * 10;
  }

  @if $mode == round {
    @return math.div(round($number * $n), $n);
  } @else if $mode == ceil {
    @return math.div(ceil($number * $n), $n);
  } @else if $mode == floor {
    @return math.div(floor($number * $n), $n);
  } @else {
    @warn '#{ $mode } is undefined keyword.';

    @return $number;
  }
}

@mixin btn-style($baseColor, $customActiveColor: null) {
  $generatedActiveColor: darken($baseColor, 15%);
  $activeColor: if(
    $customActiveColor,
    $customActiveColor,
    $generatedActiveColor
  );
  $disabledLight: lighten($inactive-gray, 8%);
  $disabledDark: $inactive-gray;

  line-height: 1;
  padding: 0.75em 1em;
  background-color: $baseColor;
  color: #fff;
  font-weight: 500;
  border: 1px solid transparent;

  &:hover,
  &:focus {
    background-color: mix($baseColor, $generatedActiveColor, 50%);
  }

  &:active {
    background-color: $generatedActiveColor;
  }

  &[disabled],
  &.disabled,
  .no-touch &[disabled],
  .no-touch &.disabled {
    background-color: $disabledDark;
    color: $disabledLight;
  }
}
