.overflow-auto,
.overflow-x-auto,
.overflow-y-auto,
.overflow-scroll,
.overflow-x-scroll,
.overflow-y-scroll {
  @include scrollbars();
}

.scrollbar-hide::-webkit-scrollbar {
  display: none;
}

/* For IE, Edge and Firefox */
.scrollbar-hide {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

#message-groups-menu {
  .popover-arrow {
    left: -120px !important;
  }
}

#notifications-menu {
  .popover-arrow {
    left: -60px !important;
  }
}

@layer utilities {
  @variants responsive {
    /* Hide scrollbar for Chrome, Safari and Opera */
    .no-scrollbar::-webkit-scrollbar {
      display: none;
    }

    /* Hide scrollbar for IE, Edge and Firefox */
    .no-scrollbar {
      -ms-overflow-style: none; /* IE and Edge */
      scrollbar-width: none; /* Firefox */
    }
  }
}

.thumb,
.thumb::-webkit-slider-thumb {
  -webkit-appearance: none;
}

/* For Chrome browsers */
.thumb::-webkit-slider-thumb {
  background-color: $smart-teal;
  border-radius: 50%;
  cursor: pointer;
  height: 20px;
  width: 20px;
  margin-top: 4px;
  pointer-events: all;
  position: relative;
}

/* For Firefox browsers */
.thumb::-moz-range-thumb {
  background-color: $smart-teal;
  border: none;
  border-radius: 50%;
  box-shadow: 0 0 1px 1px $smart-teal;
  cursor: pointer;
  height: 15px;
  width: 15px;
  margin-top: 4px;
  pointer-events: all;
  position: relative;
}
