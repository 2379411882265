#typeahead-menu {
  z-index: 1999;
}

.mentions-menu {
  min-width: 250px;
}

.typeahead-popover {
  background: #fff;
  box-shadow: 0 5px 10px #0000004d;
  border-radius: 8px;
  margin-top: 25px;
}

.typeahead-popover ul {
  padding: 0;
  list-style: none;
  margin: 0;
  border-radius: 8px;
  overflow-y: scroll;
}

.typeahead-popover ul::-webkit-scrollbar {
  display: none;
}

.typeahead-popover ul {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.typeahead-popover ul li {
  margin: 0;
  min-width: 180px;
  font-size: 14px;
  outline: none;
  cursor: pointer;
  border-radius: 8px;
}

.typeahead-popover ul li.selected {
  background: #eee;
}

.typeahead-popover li {
  margin: 0 8px;
  color: #050505;
  cursor: pointer;
  line-height: 16px;
  font-size: 15px;
  display: flex;
  align-content: center;
  flex-direction: row;
  flex-shrink: 0;
  background-color: #fff;
  border-radius: 8px;
  border: 0;
}

.typeahead-popover li.active {
  display: flex;
  width: 20px;
  height: 20px;
  background-size: contain;
}

.typeahead-popover li:first-child {
  border-radius: 8px 8px 0 0;
}

.typeahead-popover li:last-child {
  border-radius: 0 0 8px 8px;
}

.typeahead-popover li:hover {
  background-color: #eee;
}

.typeahead-popover li .text {
  display: flex;
  line-height: 20px;
  flex-grow: 1;
  min-width: 150px;
}

.typeahead-popover li .icon {
  display: flex;
  width: 20px;
  height: 20px;
  user-select: none;
  margin-right: 8px;
  line-height: 16px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.link-editor {
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
  max-width: 400px;
  width: 100%;
  opacity: 0;
  background-color: #fff;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.3);
  border-radius: 0 0 8px 8px;
  transition: opacity 0.5s;
  will-change: transform;
}

.link-editor .button {
  width: 20px;
  height: 20px;
  display: inline-block;
  padding: 6px;
  border-radius: 8px;
  cursor: pointer;
  margin: 0 2px;
}

.link-editor .button.hovered {
  width: 20px;
  height: 20px;
  display: inline-block;
  background-color: #eee;
}

.link-editor .button i,
.actions i {
  background-size: contain;
  display: inline-block;
  height: 20px;
  width: 20px;
  vertical-align: -0.25em;
}

.link-editor .button.active,
.toolbar .button.active {
  background-color: #dfe8fa;
}

.link-editor .link-input {
  display: block;
  width: calc(100% - 75px);
  box-sizing: border-box;
  margin: 12px;
  padding: 8px 12px;
  border-radius: 15px;
  background-color: #eee;
  font-size: 15px;
  color: #050505;
  border: 0;
  outline: 0;
  position: relative;
  font-family: inherit;
}

.link-editor .link-view {
  display: block;
  width: calc(100% - 24px);
  margin: 8px 12px;
  padding: 8px 12px;
  border-radius: 15px;
  font-size: 15px;
  color: #050505;
  border: 0;
  outline: 0;
  position: relative;
  font-family: inherit;
}

.link-editor .link-view a {
  display: block;
  word-break: break-word;
  width: calc(100% - 33px);
}

.link-editor div.link-edit {
  background-image: url("data:image/svg+xml,%3csvg%20xmlns='http://www.w3.org/2000/svg'%20width='16'%20height='16'%20fill='currentColor'%20class='bi%20bi-pencil-fill'%3e%3cpath%20d='M12.854.146a.5.5%200%200%200-.707%200L10.5%201.793%2014.207%205.5l1.647-1.646a.5.5%200%200%200%200-.708l-3-3zm.646%206.061L9.793%202.5%203.293%209H3.5a.5.5%200%200%201%20.5.5v.5h.5a.5.5%200%200%201%20.5.5v.5h.5a.5.5%200%200%201%20.5.5v.5h.5a.5.5%200%200%201%20.5.5v.207l6.5-6.5zm-7.468%207.468A.5.5%200%200%201%206%2013.5V13h-.5a.5.5%200%200%201-.5-.5V12h-.5a.5.5%200%200%201-.5-.5V11h-.5a.5.5%200%200%201-.5-.5V10h-.5a.499.499%200%200%201-.175-.032l-.179.178a.5.5%200%200%200-.11.168l-2%205a.5.5%200%200%200%20.65.65l5-2a.5.5%200%200%200%20.168-.11l.178-.178z'/%3e%3c/svg%3e");
  background-size: 16px;
  background-position: center;
  background-repeat: no-repeat;
  width: 35px;
  vertical-align: -0.25em;
  position: absolute;
  right: 30px;
  top: 0;
  bottom: 0;
  cursor: pointer;
}

.link-editor div.link-trash {
  background-image: url("data:image/svg+xml,%3csvg%20xmlns='http://www.w3.org/2000/svg'%20width='16'%20height='16'%20fill='currentColor'%20class='bi%20bi-trash'%3e%3cpath%20d='M5.5%205.5A.5.5%200%200%201%206%206v6a.5.5%200%200%201-1%200V6a.5.5%200%200%201%20.5-.5zm2.5%200a.5.5%200%200%201%20.5.5v6a.5.5%200%200%201-1%200V6a.5.5%200%200%201%20.5-.5zm3%20.5a.5.5%200%200%200-1%200v6a.5.5%200%200%200%201%200V6z'/%3e%3cpath%20fill-rule='evenodd'%20d='M14.5%203a1%201%200%200%201-1%201H13v9a2%202%200%200%201-2%202H5a2%202%200%200%201-2-2V4h-.5a1%201%200%200%201-1-1V2a1%201%200%200%201%201-1H6a1%201%200%200%201%201-1h2a1%201%200%200%201%201%201h3.5a1%201%200%200%201%201%201v1zM4.118%204%204%204.059V13a1%201%200%200%200%201%201h6a1%201%200%200%200%201-1V4.059L11.882%204H4.118zM2.5%203V2h11v1h-11z'/%3e%3c/svg%3e");
  background-size: 16px;
  background-position: center;
  background-repeat: no-repeat;
  width: 35px;
  vertical-align: -0.25em;
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  cursor: pointer;
}

.link-editor div.link-cancel {
  background-image: url("data:image/svg+xml,%3csvg%20xmlns='http://www.w3.org/2000/svg'%20viewBox='0%200%20512%20512'%3e%3c!--!%20Font%20Awesome%20Pro%206.1.1%20by%20@fontawesome%20-%20https://fontawesome.com%20License%20-%20https://fontawesome.com/license%20(Commercial%20License)%20Copyright%202022%20Fonticons,%20Inc.--%3e%3cpath%20d='M0%20256C0%20114.6%20114.6%200%20256%200s256%20114.6%20256%20256-114.6%20256-256%20256S0%20397.4%200%20256zm175-47.9%2047.1%2047L175%20303c-9.3%209.4-9.3%2024.6%200%2033.1%209.4%2010.2%2024.6%2010.2%2033.1%200l47-46.2%2047.9%2046.2c9.4%2010.2%2024.6%2010.2%2033.1%200%2010.2-8.5%2010.2-23.7%200-33.1l-46.2-47.9%2046.2-47c10.2-8.5%2010.2-23.7%200-33.1-8.5-9.3-23.7-9.3-33.1%200l-47.9%2047.1-47-47.1c-8.5-9.3-23.7-9.3-33.1%200-9.3%209.4-9.3%2024.6%200%2033.1z'/%3e%3c/svg%3e");
  background-size: 16px;
  background-position: center;
  background-repeat: no-repeat;
  width: 35px;
  vertical-align: -0.25em;
  margin-right: 28px;
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  cursor: pointer;
}

.link-editor div.link-confirm {
  background-image: url("data:image/svg+xml,%3csvg%20xmlns='http://www.w3.org/2000/svg'%20viewBox='0%200%2050%2050'%3e%3ccircle%20cx='25'%20cy='25'%20r='25'%20fill='%23000'/%3e%3cpath%20fill='none'%20stroke='%23fff'%20stroke-width='4'%20stroke-linecap='round'%20stroke-linejoin='round'%20stroke-miterlimit='10'%20d='M38%2015%2022%2033l-10-8'/%3e%3c/svg%3e");
  background-size: 16px;
  background-position: center;
  background-repeat: no-repeat;
  width: 35px;
  vertical-align: -0.25em;
  margin-right: 2px;
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  cursor: pointer;
}

.link-editor .link-input a {
  color: #216fdb;
  text-decoration: underline;
  white-space: nowrap;
  overflow: hidden;
  margin-right: 30px;
  text-overflow: ellipsis;
}

.link-editor .link-input a:hover {
  text-decoration: underline;
}

.link-editor .font-size-wrapper,
.link-editor .font-family-wrapper {
  display: flex;
  margin: 0 4px;
}

.link-editor select {
  padding: 6px;
  border: none;
  background-color: #00000013;
  border-radius: 4px;
}

.floating-text-format-popup {
  display: flex;
  background: #fff;
  padding: 4px;
  vertical-align: middle;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
  opacity: 0;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.3);
  border-radius: 8px;
  transition: opacity 0.5s;
  height: 35px;
  will-change: transform;
}

.floating-text-format-popup button.popup-item {
  border: 0;
  display: flex;
  background: none;
  border-radius: 10px;
  padding: 8px;
  cursor: pointer;
  vertical-align: middle;
}

.floating-text-format-popup button.popup-item:disabled {
  cursor: not-allowed;
}

.floating-text-format-popup button.popup-item.spaced {
  margin-right: 2px;
}

.floating-text-format-popup button.popup-item i.format {
  background-size: contain;
  height: 18px;
  width: 18px;
  margin-top: -3px;
  vertical-align: -0.25em;
  display: flex;
  opacity: 0.6;
}

.floating-text-format-popup button.popup-item:disabled i.format {
  opacity: 0.2;
}

.floating-text-format-popup button.popup-item.active {
  background-color: rgba(223, 232, 250, 0.3);
}

.floating-text-format-popup button.popup-item.active i {
  opacity: 1;
}

.floating-text-format-popup .popup-item:hover:not([disabled]) {
  background-color: #eee;
}

.floating-text-format-popup select.popup-item {
  border: 0;
  display: flex;
  background: none;
  border-radius: 10px;
  padding: 8px;
  vertical-align: middle;
  -webkit-appearance: none;
  -moz-appearance: none;
  width: 70px;
  font-size: 14px;
  color: #777;
  text-overflow: ellipsis;
}

.floating-text-format-popup select.code-language {
  text-transform: capitalize;
  width: 130px;
}

.floating-text-format-popup .popup-item .text {
  display: flex;
  line-height: 20px;
  vertical-align: middle;
  font-size: 14px;
  color: #777;
  text-overflow: ellipsis;
  width: 70px;
  overflow: hidden;
  height: 20px;
  text-align: left;
}

.floating-text-format-popup .popup-item .icon {
  display: flex;
  width: 20px;
  height: 20px;
  user-select: none;
  margin-right: 8px;
  line-height: 16px;
  background-size: contain;
}

.floating-text-format-popup i.chevron-down {
  margin-top: 3px;
  width: 16px;
  height: 16px;
  display: flex;
  user-select: none;
}

.floating-text-format-popup i.chevron-down.inside {
  width: 16px;
  height: 16px;
  display: flex;
  margin-left: -25px;
  margin-top: 11px;
  margin-right: 10px;
  pointer-events: none;
}

.floating-text-format-popup .divider {
  width: 1px;
  background-color: #eee;
  margin: 0 4px;
}

@media (max-width: 1024px) {
  .floating-text-format-popup button.insert-comment {
    display: none;
  }
}

i.palette {
  background-image: url("data:image/svg+xml,%3csvg%20xmlns='http://www.w3.org/2000/svg'%20width='16'%20height='16'%20fill='currentColor'%20class='bi%20bi-palette'%3e%3cpath%20d='M8%205a1.5%201.5%200%201%200%200-3%201.5%201.5%200%200%200%200%203zm4%203a1.5%201.5%200%201%200%200-3%201.5%201.5%200%200%200%200%203zM5.5%207a1.5%201.5%200%201%201-3%200%201.5%201.5%200%200%201%203%200zm.5%206a1.5%201.5%200%201%200%200-3%201.5%201.5%200%200%200%200%203z'/%3e%3cpath%20d='M16%208c0%203.15-1.866%202.585-3.567%202.07C11.42%209.763%2010.465%209.473%2010%2010c-.603.683-.475%201.819-.351%202.92C9.826%2014.495%209.996%2016%208%2016a8%208%200%201%201%208-8zm-8%207c.611%200%20.654-.171.655-.176.078-.146.124-.464.07-1.119-.014-.168-.037-.37-.061-.591-.052-.464-.112-1.005-.118-1.462-.01-.707.083-1.61.704-2.314.369-.417.845-.578%201.272-.618.404-.038.812.026%201.16.104.343.077.702.186%201.025.284l.028.008c.346.105.658.199.953.266.653.148.904.083.991.024C14.717%209.38%2015%209.161%2015%208a7%207%200%201%200-7%207z'/%3e%3c/svg%3e");
}

i.bucket {
  background-image: url("data:image/svg+xml,%3csvg%20xmlns='http://www.w3.org/2000/svg'%20width='16'%20height='16'%20fill='currentColor'%20class='bi%20bi-paint-bucket'%3e%3cpath%20d='M6.192%202.78c-.458-.677-.927-1.248-1.35-1.643a2.972%202.972%200%200%200-.71-.515c-.217-.104-.56-.205-.882-.02-.367.213-.427.63-.43.896-.003.304.064.664.173%201.044.196.687.556%201.528%201.035%202.402L.752%208.22c-.277.277-.269.656-.218.918.055.283.187.593.36.903.348.627.92%201.361%201.626%202.068.707.707%201.441%201.278%202.068%201.626.31.173.62.305.903.36.262.05.64.059.918-.218l5.615-5.615c.118.257.092.512.05.939-.03.292-.068.665-.073%201.176v.123h.003a1%201%200%200%200%201.993%200H14v-.057a1.01%201.01%200%200%200-.004-.117c-.055-1.25-.7-2.738-1.86-3.494a4.322%204.322%200%200%200-.211-.434c-.349-.626-.92-1.36-1.627-2.067-.707-.707-1.441-1.279-2.068-1.627-.31-.172-.62-.304-.903-.36-.262-.05-.64-.058-.918.219l-.217.216zM4.16%201.867c.381.356.844.922%201.311%201.632l-.704.705c-.382-.727-.66-1.402-.813-1.938a3.283%203.283%200%200%201-.131-.673c.091.061.204.15.337.274zm.394%203.965c.54.852%201.107%201.567%201.607%202.033a.5.5%200%201%200%20.682-.732c-.453-.422-1.017-1.136-1.564-2.027l1.088-1.088c.054.12.115.243.183.365.349.627.92%201.361%201.627%202.068.706.707%201.44%201.278%202.068%201.626.122.068.244.13.365.183l-4.861%204.862a.571.571%200%200%201-.068-.01c-.137-.027-.342-.104-.608-.252-.524-.292-1.186-.8-1.846-1.46-.66-.66-1.168-1.32-1.46-1.846-.147-.265-.225-.47-.251-.607a.573.573%200%200%201-.01-.068l3.048-3.047zm2.87-1.935a2.44%202.44%200%200%201-.241-.561c.135.033.324.11.562.241.524.292%201.186.8%201.846%201.46.45.45.83.901%201.118%201.31a3.497%203.497%200%200%200-1.066.091%2011.27%2011.27%200%200%201-.76-.694c-.66-.66-1.167-1.322-1.458-1.847z'/%3e%3c/svg%3e");
}

i.bold {
  background-image: url("data:image/svg+xml,%3csvg%20xmlns='http://www.w3.org/2000/svg'%20width='16'%20height='16'%20fill='currentColor'%20class='bi%20bi-type-bold'%3e%3cpath%20d='M8.21%2013c2.106%200%203.412-1.087%203.412-2.823%200-1.306-.984-2.283-2.324-2.386v-.055a2.176%202.176%200%200%200%201.852-2.14c0-1.51-1.162-2.46-3.014-2.46H3.843V13H8.21zM5.908%204.674h1.696c.963%200%201.517.451%201.517%201.244%200%20.834-.629%201.32-1.73%201.32H5.908V4.673zm0%206.788V8.598h1.73c1.217%200%201.88.492%201.88%201.415%200%20.943-.643%201.449-1.832%201.449H5.907z'/%3e%3c/svg%3e");
}

i.italic {
  background-image: url("data:image/svg+xml,%3csvg%20xmlns='http://www.w3.org/2000/svg'%20width='16'%20height='16'%20fill='currentColor'%20class='bi%20bi-type-italic'%3e%3cpath%20d='M7.991%2011.674%209.53%204.455c.123-.595.246-.71%201.347-.807l.11-.52H7.211l-.11.52c1.06.096%201.128.212%201.005.807L6.57%2011.674c-.123.595-.246.71-1.346.806l-.11.52h3.774l.11-.52c-1.06-.095-1.129-.211-1.006-.806z'/%3e%3c/svg%3e");
}

i.clear {
  background-image: url("data:image/svg+xml,%3csvg%20xmlns='http://www.w3.org/2000/svg'%20width='16'%20height='16'%20fill='currentColor'%20class='bi%20bi-trash'%3e%3cpath%20d='M5.5%205.5A.5.5%200%200%201%206%206v6a.5.5%200%200%201-1%200V6a.5.5%200%200%201%20.5-.5zm2.5%200a.5.5%200%200%201%20.5.5v6a.5.5%200%200%201-1%200V6a.5.5%200%200%201%20.5-.5zm3%20.5a.5.5%200%200%200-1%200v6a.5.5%200%200%200%201%200V6z'/%3e%3cpath%20fill-rule='evenodd'%20d='M14.5%203a1%201%200%200%201-1%201H13v9a2%202%200%200%201-2%202H5a2%202%200%200%201-2-2V4h-.5a1%201%200%200%201-1-1V2a1%201%200%200%201%201-1H6a1%201%200%200%201%201-1h2a1%201%200%200%201%201%201h3.5a1%201%200%200%201%201%201v1zM4.118%204%204%204.059V13a1%201%200%200%200%201%201h6a1%201%200%200%200%201-1V4.059L11.882%204H4.118zM2.5%203V2h11v1h-11z'/%3e%3c/svg%3e");
}

i.code {
  background-image: url("data:image/svg+xml,%3csvg%20xmlns='http://www.w3.org/2000/svg'%20width='16'%20height='16'%20fill='currentColor'%20class='bi%20bi-code'%3e%3cpath%20d='M5.854%204.854a.5.5%200%201%200-.708-.708l-3.5%203.5a.5.5%200%200%200%200%20.708l3.5%203.5a.5.5%200%200%200%20.708-.708L2.707%208l3.147-3.146zm4.292%200a.5.5%200%200%201%20.708-.708l3.5%203.5a.5.5%200%200%201%200%20.708l-3.5%203.5a.5.5%200%200%201-.708-.708L13.293%208l-3.147-3.146z'/%3e%3c/svg%3e");
}

i.underline {
  background-image: url("data:image/svg+xml,%3csvg%20xmlns='http://www.w3.org/2000/svg'%20width='16'%20height='16'%20fill='currentColor'%20class='bi%20bi-type-underline'%3e%3cpath%20d='M5.313%203.136h-1.23V9.54c0%202.105%201.47%203.623%203.917%203.623s3.917-1.518%203.917-3.623V3.136h-1.23v6.323c0%201.49-.978%202.57-2.687%202.57-1.709%200-2.687-1.08-2.687-2.57V3.136zM12.5%2015h-9v-1h9v1z'/%3e%3c/svg%3e");
}

i.strikethrough {
  background-image: url("data:image/svg+xml,%3csvg%20xmlns='http://www.w3.org/2000/svg'%20width='16'%20height='16'%20fill='currentColor'%20class='bi%20bi-type-strikethrough'%3e%3cpath%20d='M6.333%205.686c0%20.31.083.581.27.814H5.166a2.776%202.776%200%200%201-.099-.76c0-1.627%201.436-2.768%203.48-2.768%201.969%200%203.39%201.175%203.445%202.85h-1.23c-.11-1.08-.964-1.743-2.25-1.743-1.23%200-2.18.602-2.18%201.607zm2.194%207.478c-2.153%200-3.589-1.107-3.705-2.81h1.23c.144%201.06%201.129%201.703%202.544%201.703%201.34%200%202.31-.705%202.31-1.675%200-.827-.547-1.374-1.914-1.675L8.046%208.5H1v-1h14v1h-3.504c.468.437.675.994.675%201.697%200%201.826-1.436%202.967-3.644%202.967z'/%3e%3c/svg%3e");
}

i.subscript {
  background-image: url("data:image/svg+xml,%3csvg%20width='16'%20height='16'%20fill='none'%20xmlns='http://www.w3.org/2000/svg'%3e%3cpath%20d='M11.354%2014.5v-.665l1.553-1.438c.132-.128.243-.243.332-.345.091-.102.16-.203.207-.3.047-.1.07-.207.07-.322a.574.574%200%200%200-.326-.546.748.748%200%200%200-.343-.077.721.721%200%200%200-.35.082.557.557%200%200%200-.23.232.753.753%200%200%200-.08.36h-.876c0-.286.065-.534.194-.744.13-.21.31-.373.543-.488.233-.115.502-.172.806-.172.312%200%20.584.055.816.166.233.11.414.261.543.456.13.194.194.418.194.669%200%20.165-.033.327-.098.488-.064.16-.178.339-.343.535a7.918%207.918%200%200%201-.697.7l-.637.625v.03h1.832v.754h-3.11ZM5.04%204.273%206.96%207.469h.068l1.93-3.196h1.803L8.073%208.636%2010.805%2013H8.972L7.03%209.825h-.068L5.018%2013H3.194l2.757-4.364-2.723-4.363H5.04Z'%20fill='currentColor'/%3e%3c/svg%3e");
}

i.superscript {
  background-image: url("data:image/svg+xml,%3csvg%20width='16'%20height='16'%20fill='none'%20xmlns='http://www.w3.org/2000/svg'%3e%3cpath%20d='M11.354%206v-.665l1.553-1.438c.132-.128.243-.243.332-.345a1.31%201.31%200%200%200%20.207-.3c.047-.1.07-.207.07-.322a.574.574%200%200%200-.326-.545.748.748%200%200%200-.343-.077.721.721%200%200%200-.35.08.557.557%200%200%200-.23.233.753.753%200%200%200-.08.36h-.876c0-.286.065-.534.194-.744.13-.21.31-.373.543-.488.233-.115.502-.172.806-.172.312%200%20.584.055.816.166.233.11.414.261.543.456.13.194.194.417.194.669%200%20.165-.033.327-.098.488-.064.16-.178.339-.343.535a7.92%207.92%200%200%201-.697.7l-.637.625v.03h1.832V6h-3.11ZM5.04%204.273%206.96%207.469h.068l1.93-3.196h1.803L8.073%208.636%2010.805%2013H8.972L7.03%209.825h-.068L5.018%2013H3.194l2.757-4.364-2.723-4.363H5.04Z'%20fill='currentColor'/%3e%3c/svg%3e");
}

i.link {
  background-image: url("data:image/svg+xml,%3csvg%20xmlns='http://www.w3.org/2000/svg'%20width='16'%20height='16'%20fill='currentColor'%20class='bi%20bi-link'%3e%3cpath%20d='M6.354%205.5H4a3%203%200%200%200%200%206h3a3%203%200%200%200%202.83-4H9c-.086%200-.17.01-.25.031A2%202%200%200%201%207%2010.5H4a2%202%200%201%201%200-4h1.535c.218-.376.495-.714.82-1z'/%3e%3cpath%20d='M9%205.5a3%203%200%200%200-2.83%204h1.098A2%202%200%200%201%209%206.5h3a2%202%200%201%201%200%204h-1.535a4.02%204.02%200%200%201-.82%201H12a3%203%200%201%200%200-6H9z'/%3e%3c/svg%3e");
}

i.horizontal-rule {
  background-image: url("data:image/svg+xml,%3csvg%20xmlns='http://www.w3.org/2000/svg'%20width='16'%20height='16'%20fill='currentColor'%20class='bi%20bi-file-break'%3e%3cpath%20d='M0%2010.5a.5.5%200%200%201%20.5-.5h15a.5.5%200%200%201%200%201H.5a.5.5%200%200%201-.5-.5zM12%200H4a2%202%200%200%200-2%202v7h1V2a1%201%200%200%201%201-1h8a1%201%200%200%201%201%201v7h1V2a2%202%200%200%200-2-2zm2%2012h-1v2a1%201%200%200%201-1%201H4a1%201%200%200%201-1-1v-2H2v2a2%202%200%200%200%202%202h8a2%202%200%200%200%202-2v-2z'/%3e%3c/svg%3e");
}

.icon.plus {
  background-image: url("data:image/svg+xml,%3csvg%20xmlns='http://www.w3.org/2000/svg'%20width='16'%20height='16'%20fill='currentColor'%20class='bi%20bi-plus'%3e%3cpath%20d='M8%204a.5.5%200%200%201%20.5.5v3h3a.5.5%200%200%201%200%201h-3v3a.5.5%200%200%201-1%200v-3h-3a.5.5%200%200%201%200-1h3v-3A.5.5%200%200%201%208%204z'/%3e%3c/svg%3e");
}

.icon.caret-right {
  background-image: url("data:image/svg+xml,%3csvg%20xmlns='http://www.w3.org/2000/svg'%20width='16'%20height='16'%20fill='currentColor'%20class='bi%20bi-caret-right-fill'%3e%3cpath%20d='m12.14%208.753-5.482%204.796c-.646.566-1.658.106-1.658-.753V3.204a1%201%200%200%201%201.659-.753l5.48%204.796a1%201%200%200%201%200%201.506z'/%3e%3c/svg%3e");
}

.icon.dropdown-more {
  background-image: url("data:image/svg+xml,%3csvg%20width='16'%20height='16'%20fill='none'%20xmlns='http://www.w3.org/2000/svg'%3e%3cpath%20d='M4.648%204.475%201.824%2012.25H.67l3.252-8.531h.744l-.018.756Zm2.368%207.775-2.83-7.775-.018-.756h.744l3.264%208.531h-1.16Zm-.147-3.158v.926H2.076v-.926H6.87Zm6.024%202.074V7.902c0-.25-.051-.466-.153-.65a.997.997%200%200%200-.445-.434c-.2-.101-.445-.152-.738-.152-.274%200-.514.047-.721.14a1.255%201.255%200%200%200-.48.37.809.809%200%200%200-.17.492H9.101c0-.227.058-.451.175-.674.118-.223.286-.424.504-.603.223-.184.489-.329.797-.434.313-.11.66-.164%201.043-.164.461%200%20.867.078%201.219.234.355.157.633.393.832.71.203.312.305.704.305%201.177v2.953c0%20.211.017.436.052.674.04.238.096.443.17.615v.094h-1.13a2.022%202.022%200%200%201-.13-.498%204.011%204.011%200%200%201-.046-.586Zm.187-2.76.012.762h-1.096c-.309%200-.584.025-.826.076a1.89%201.89%200%200%200-.61.217.979.979%200%200%200-.504.879c0%20.2.046.38.135.545a.98.98%200%200%200%20.405.392c.183.094.408.141.674.141.332%200%20.625-.07.878-.211a1.83%201.83%200%200%200%20.604-.516c.152-.203.234-.4.246-.591l.463.521a1.572%201.572%200%200%201-.223.545%202.607%202.607%200%200%201-1.2%201.025%202.328%202.328%200%200%201-.927.176c-.43%200-.806-.084-1.13-.252a1.933%201.933%200%200%201-.75-.674%201.784%201.784%200%200%201-.264-.955c0-.34.066-.638.199-.896a1.73%201.73%200%200%201%20.574-.65c.25-.176.551-.31.903-.399a4.76%204.76%200%200%201%201.177-.135h1.26Z'%20fill='%23000'/%3e%3c/svg%3e");
}

.icon.font-color {
  background-image: url("data:image/svg+xml,%3csvg%20xmlns='http://www.w3.org/2000/svg'%20width='14'%20height='14'%20viewBox='0%200%20512%20512'%3e%3cpath%20fill='%23777'%20d='M221.631%20109%20109.92%20392h58.055l24.079-61h127.892l24.079%2061h58.055L290.369%20109Zm-8.261%20168L256%20169l42.63%20108Z'/%3e%3c/svg%3e");
}

.icon.font-family {
  background-image: url("data:image/svg+xml,%3csvg%20xmlns='http://www.w3.org/2000/svg'%20width='16'%20height='16'%20fill='currentColor'%20class='bi%20bi-fonts'%3e%3cpath%20d='M12.258%203h-8.51l-.083%202.46h.479c.26-1.544.758-1.783%202.693-1.845l.424-.013v7.827c0%20.663-.144.82-1.3.923v.52h4.082v-.52c-1.162-.103-1.306-.26-1.306-.923V3.602l.431.013c1.934.062%202.434.301%202.693%201.846h.479L12.258%203z'/%3e%3c/svg%3e");
}

.icon.bg-color {
  background-image: url("data:image/svg+xml,%3csvg%20width='16'%20height='16'%20viewBox='0%200%2048%2048'%20fill='none'%20xmlns='http://www.w3.org/2000/svg'%3e%3cpath%20fill='%23fff'%20fill-opacity='.01'%20d='M0%200h48v48H0z'/%3e%3cpath%20fill-rule='evenodd'%20clip-rule='evenodd'%20d='M37%2037a4%204%200%200%200%204-4c0-1.473-1.333-3.473-4-6-2.667%202.527-4%204.527-4%206a4%204%200%200%200%204%204Z'%20fill='%23777'/%3e%3cpath%20d='m20.854%205.504%203.535%203.536'%20stroke='%23777'%20stroke-width='4'%20stroke-linecap='round'/%3e%3cpath%20d='M23.682%208.333%208.125%2023.889%2019.44%2035.203l15.556-15.557L23.682%208.333Z'%20stroke='%23777'%20stroke-width='4'%20stroke-linejoin='round'/%3e%3cpath%20d='m12%2020.073%2016.961%205.577M4%2043h40'%20stroke='%23777'%20stroke-width='4'%20stroke-linecap='round'/%3e%3c/svg%3e");
}

.editor-text-underline {
  text-decoration: underline;
}
.editor-text-strikethrough {
  text-decoration: line-through;
}
.editor-text-underlineStrikethrough {
  text-decoration: underline line-through;
}
