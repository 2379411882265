@use "sass:math";

@import "../../styles/mixins.scss";

.top_nav_bar {
  width: 100%;
  background-color: #39ae86;
  background-image: linear-gradient(160deg, #24a9a7, #4db364),
    linear-gradient(to right, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.15));
  top: 0;
  right: 0;
  left: 0;
  position: fixed;
  width: 100%;
  z-index: 999;
  transition: 0.2s ease-in-out;
}

.top_nav_bar_container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
  max-width: 1328px;
  height: 60px;
}

.top_nav_bar_container_mobile {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  max-width: 1352px;
  height: 54px;

  .nav_links_mobile {
    a {
      @include btn-style(transparent);
      font-size: 15px;
      color: #fff;
      font-weight: 400;

      display: flex;
      align-items: center;
    }

    a:hover {
      opacity: 0.8;
      font-weight: 400;
    }

    .backIcon {
      height: 1em;
      margin-bottom: math.div(-3em, 15);
      margin-top: math.div(-3em, 15);
      width: 1em;

      line,
      polyline {
        fill: none;
        stroke-miterlimit: 10;
        stroke-width: 1.5;
        stroke: #fff;
      }
    }

    svg {
      margin-right: 10px;
    }
  }

  .nav {
    flex: 1;
    display: flex;
    list-style-type: none;
    margin: 0;
    padding: 0;

    .item {
      min-width: 0;
      display: flex;
      flex: 1 0 0;
      min-width: 60px;

      a {
        margin: 0;
        padding: 0;
        align-items: center;
        display: flex;
        height: 54px;
        justify-content: center;
        text-align: center;
        width: 100%;
        color: #fff;

        .icon {
          align-items: center;
          display: flex;
          justify-content: center;
          position: relative;

          .fa {
            font-size: 1.5em;
          }
        }
      }
    }
  }
}

.left_section {
  // display: flex;
  // align-items: center;

  flex: 1;
  overflow: hidden;
}
.right_section {
  display: flex;
  align-items: center;
  margin-right: 17px;

  @media screen and #{$mq-handheld} {
    margin-right: 0;
  }
}

.logo {
  border-radius: 5px;
  margin-left: 30px;
  margin-right: 20px;
  align-items: center;
  cursor: pointer;
  width: 35px;
  height: 35px;
  overflow: hidden;
  background-color: black;

  span {
    transition: 1s;
    transform: rotate(0deg);

    &:hover {
      transform: rotate(180deg);
    }
  }
}

.nav_links {
  display: flex;
  align-items: center;
  height: 60px;
}

.nav_links a {
  margin-left: 10px;
  margin-right: 10px;
  height: 100%;
  display: flex;
  align-items: center;

  font-size: 16px;
  color: #fff;
  font-weight: 500;

  span {
    position: relative;

    &::after {
      border-top: 2px solid #fff;
      bottom: -4px;
      content: " ";
      left: 0;
      position: absolute;
      -ms-transform: scaleX(0);
      transform: scaleX(0);
      transition: 0.2s ease-out;
      width: 100%;
    }
  }

  &:hover,
  &.active {
    color: #fff;

    ::after {
      -webkit-backface-visibility: hidden;
      backface-visibility: hidden;
      -ms-transform: scaleX(1);
      transform: scaleX(1);
    }
  }
}

.btn {
  color: #fff;
  line-height: 1;
  font-size: 13px;
  padding: 0.75em 1.25em;
  border-radius: 20px;
  font-weight: 500;
  border: none;
  margin-left: 12.5px;
  margin-right: 12.5px;
  min-width: 80px;
  border: 1px solid transparent;
}

.btn:hover {
  opacity: 0.8;
}

.btn_primary {
  background-color: #b63998;
}

.btn_secondary {
  background-color: rgba(255, 255, 255, 0.2);
}

.btnIcon {
  @include btn-style(transparent);
  height: 60px;
  padding: 0;

  &:hover {
    background-color: rgba(0, 0, 0, 0.0666667);
  }
}

.right_section svg {
  color: #fff;
  font-size: 23px;
  font-weight: 700;
  min-width: 60px;

  @media screen and #{$mq-handheld} {
    padding: 0 5px;
    font-size: 20px;
    min-width: 45px;
  }
}

.submenu_popover {
  margin-top: -10px;
  > :first-child {
    display: none;
  }
}

.submenu_container {
  height: 100%;
}

.submenu {
  position: absolute;
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0 1px 10px rgb(0 0 0 / 13%), 0 0 2px 1px rgb(0 0 0 / 13%);
  overflow: hidden;

  ul {
    list-style-type: none;
    margin: 0;
    padding: 10px 0;
  }

  li {
    &:hover {
      background-color: rgba(36, 169, 167, 0.3);

      a {
        color: #1f1f20;
      }
    }
  }

  a {
    color: #1f1f20;
    display: block;
    padding: 7px 10px;
  }
}

:global(.bos-theme) {
  .top_nav_bar {
    background-color: #5f686f;
    background-image: none;
  }

  .logo {
    background-color: transparent;
  }

  .btn_primary {
    background-color: #9a1421;
  }
}
