@use "sass:math";

// Typography
$sans-serif: var(--font-roboto), "helvetica neue", sans-serif;
$serif: var(--font-pt-serif), "serif";
$base-font-family: $sans-serif;
$header-font-family: $base-font-family;

// Font Sizes
$base-font-size: 1em;
$h1-font-size: $base-font-size * 2.25;
$h2-font-size: $base-font-size * 2;
$h3-font-size: $base-font-size * 1.75;
$h4-font-size: $base-font-size * 1.5;
$h5-font-size: $base-font-size * 1.25;
$h6-font-size: $base-font-size;

// Line height
$base-line-height: 1.5;
$header-line-height: 1.25;

// Other Sizes
$base-border-radius: 2px;
$button-border-radius: 20px;
$base-spacing: $base-line-height * 1em;
$base-z-index: 0;

// Colors
$blue: #477dca;
$dark-gray: #333;
$medium-gray: #999;
$light-gray: #ddd;
$light-red: #fbe3e4;
$light-yellow: #fff6bf;
$light-green: #e6efc2;
$smart-teal: #24a9a7;
$subtle-black: #3a3a3c;

// Background Color
$base-background-color: white;

// Font Colors
$base-font-color: $subtle-black;
$base-accent-color: $smart-teal;

// Link Colors
$base-link-color: $base-accent-color;
$hover-link-color: darken($base-accent-color, 15);
$hover-link-color-bright: darken($base-accent-color, 10);
$base-button-color: $base-link-color;
$hover-button-color: $hover-link-color;

// Flash Colors
$alert-color: $light-yellow;
$error-color: $light-red;
$notice-color: lighten($base-accent-color, 40);
$success-color: $light-green;

// Border color
$base-border-color: $light-gray;
$base-border: 1px solid $base-border-color;

// Forms
$form-border-color: $base-border-color;
$form-border-color-hover: darken($base-border-color, 10);
$form-border-color-focus: $base-accent-color;
$form-border-radius: $base-border-radius;
$form-box-shadow: inset 0 1px 3px rgba(black, 0.06);
$form-box-shadow-focus: $form-box-shadow,
  0 0 5px rgba(darken($form-border-color-focus, 5), 0.7);
$form-font-size: $base-font-size;
$form-font-family: $base-font-family;

// Colors
// Smart Blue is our old logo color. Now obsolete
// $smart-blue: #00a1e0;
$karma-green: #77bd22; // For logo use only
$oceania: #279fc4; // For logo use only
$smart-teal: #24a9a7; //mix($karma-green, $smart-blue, 30%);
$jade: #4db365; //mix($karma-green, $smart-blue, 65%);
$active-black: #1f1f20;
$charcoal: #474747;
$gray-gray: #555;
$gray-gray-shade: rgba(#000, math.div(10, 15));
$shady-gray: #999;
$shady-gray-shade: rgba(#000, math.div(6, 15));
$shade-hover: rgba(#000, math.div(1, 15));
$shade-active: rgba(#000, math.div(2, 15));
$border-shade: rgba(#000, math.div(2, 15));
$border-shade-opaque: mix(#000, #fff, 10%);
$inactive-gray: #ccc;
$inactive-gray-shade: rgba(#000, math.div(3, 15));
$nearly-white: #eee;
$nearly-white-shade: rgba(#000, math.div(1, 15));
$ink-pink: #b63998;
$mellow-yellow: #f5d328;
$bearish-red: #ac334b;
$bullish-green: #75ac33; //desaturate($karma-green, 15%);
$bearish-color: $bearish-red;
$bullish-color: $bullish-green;
$sea-green: #1c896e;
$tea-orange: #f59331;
$duck-egg: #91b0ba;
$snow-gum: #739b93;
$smart-teal-white-50: mix(#fff, $smart-teal, 50%);
$smart-teal-white-85: mix(#fff, $smart-teal, 85%);
$smart-teal-opacity-30: rgba($smart-teal, 0.3);
$smart-teal-opacity-50: rgba($smart-teal, 0.5);
$smart-teal-tint: $smart-teal-white-85;
$linkedin-color: #007bb6;
$twitter-color: #1da1f2;
$whatsapp-color: #25d366;
$olivine: #7eb872;
$jade: #4db364;

// Charts only
// Try not to use these... Uncomment if you need to. Update: Have been used in widgets as well i.e Trending
$grass: #9fc571;
// $tea-green: #e1e876;
$intelligent-silver: #707070;
$utah-sky: #0276be;
$denim: #0d4567;
$cornflower: #86bbe5;
$glacial-blue: #05bdd9;
$notice-color: lighten($mellow-yellow, 10%);
$mark-color: #dff1d5;
$error-color: lighten($bearish-red, 10%);
$info-color: lighten($inactive-gray, 10%);
$success-color: $jade;
$highlight-color: $smart-teal;
$bar-background-color: mix($nearly-white, #fff, 60%);
$unread-color: mix($mellow-yellow, #fff, 40%);
$compliance-info-color: mix($mellow-yellow, #fff, 20%);

// Gradient
$gradient-top: $smart-teal;
$gradient-bottom: $jade;
$gradient-one: mix($gradient-bottom, $gradient-top, math.div(100%, 3));
$gradient-average: mix($gradient-bottom, $gradient-top);
$gradient-two: mix($gradient-bottom, $gradient-top, math.div(200%, 3));
$sidebar-gradient: linear-gradient(160deg, $gradient-top, $gradient-bottom);
$sidebar-overlay: linear-gradient(to right, rgba(#fff, 0), rgba(#fff, 0.15));
$highlight-average: mix($ink-pink, $tea-orange);
$highlight-gradient: linear-gradient(160deg, $ink-pink, $tea-orange);

// Search Results
$smoke: #67727a;
$fern: #9eab7f;
$tide-pool: #6891ad;
$duck-egg: #91b0ba;
$snow-gum: #739b93;
$harp: #e1efe6;

// premium service colors
$steel-blue: #326470;
$neptune: #6fa9b8;
$locker-color: $smoke;
$entity-color: $fern;
$insight-color: $tide-pool;
$report-color: $duck-egg;
$account-color: $shady-gray;
$discussion-color: $shady-gray;
$tool-color: $gradient-average;
$tool-ip-color: $utah-sky;
$tool-sale-color: $glacial-blue;
$sector-color: $fern;
$country-color: $duck-egg;
$vertical-color: $snow-gum;
$premium-service-color: $glacial-blue;
$comment-box-color: $nearly-white;
$comment-box-color-me: $harp;

// Insight Appreciation Reasons
$reason-actionable-color: $cornflower;
$reason-substantive-color: $bullish-color;
$reason-differentiated-color: $sea-green;
$reason-logical-color: $denim;

// Box shadows
$nav-topbar-shadow: 0 1px 4px $inactive-gray-shade;
$nav-bottombar-shadow: 0 -1px 4px $inactive-gray-shade;
$card-shadow: 0 1px 2px $inactive-gray-shade;
$popup-shadow: 0 1px 10px $border-shade, 0 0 2px 1px $border-shade;
$textarea-shadow: $form-box-shadow;
$textarea-shadow-focus: $form-box-shadow-focus;
$switch-shadow: 0 2px 5px $shady-gray-shade;

// Font families
$font-interface: $base-font-family;
$font-content-emphasis: $sans-serif;
$font-content: $serif;
$font-awesome: "Font Awesome 5 Free";

// Font sizes
$fontsize-xxl: 36px;
$fontsize-mobile-xxl: 30px;
$fontsize-xl: 22px;
$fontsize-lg: 18px;
$fontsize-content: 17px;
$fontsize-md: 15px;
$fontsize-sm: 13px;
$fontsize-xs: 12px;
$fontsize-xxs: 11px;

// Font size for specific elements
// Define only styles that will be used across components
$fontsize-insight-headline-full: $fontsize-xxl;

// Font sizes for public site
$fontsize-public-title-hero: 72px;
$fontsize-public-subtitle-hero: 21px;
$fontsize-public-title-lg: 48px;
$fontsize-public-subtitle-lg: 19px;
$fontsize-public-title-md: 40px;
$fontsize-public-subtitle-md: 17px;
$fontsize-public-title-sm: 22px;
$fontsize-public-subtitle-sm: 15px;

// Line-heights
$lineheight-xxl: math.div(42, 36);
$lineheight-xl: math.div(30, 22);
$lineheight-lg: math.div(28, 18);
$lineheight-md: math.div(22, 15);
$lineheight-sm: math.div(18, 13);
$lineheight-xs: math.div(16, 12);
$lineheight-xxs: math.div(16, 11);
$lineheight-snippet: 20px;
$lineheight-content: math.div(30, 17);

// Line height for specific elements
// Define only styles that will be used across components
$lineheight-insight-headline: $lineheight-xxl;

// Line-heights for public site
$lineheight-public-title-hero: math.div(70, 72);
$lineheight-public-subtitle-hero: math.div(29, 21);
$lineheight-public-title-lg: math.div(55, 48);
$lineheight-public-subtitle-lg: math.div(27, 19);
$lineheight-public-title-lg: (48 * 0.025);
$lineheight-public-subtitle-lg: math.div(21, 17);
$lineheight-public-title-sm: math.div(27, 22);
$lineheight-public-subtitle-sm: math.div(18, 15);

// Lengths
$notification-snippet-badge: 17px;
$imgsize-xs: 20px;
$imgsize-sm: 35px;
$imgsize-md: 55px;
$imgsize-mobile-lg: 70px;
$imgsize-lg: 90px;
$imgsize-xl: 200px;
$card-border-radius: 5px;
$modal-border-radius: 8px;
$popup-border-radius: 15px;
$comment-border-radius: 8px;
$content-padding-xs: 5px;
$content-padding-sm: 10px;
$content-padding: 15px;
$content-padding-lg: 25px;
$content-padding-ll: 30px;
$content-padding-xl: 50px;
$icon-min-width: 60px;
$icon-max-width: 80px;
$footer-height: 38px;
$nav-sidebar-compact-width: 71px; // update consts/positions navSearchHeight value as well
$nav-tablet-height: 60px;
$nav-mobile-height: 48px;
$nav-search-height: 60px;
$nav-notifications-width: 100px;
$side-comment-marker-height: 45px;
$side-comment-marker-width: 55px;
$smart-banner-height: 78px;
$nav-sidebar-width: 255px;
$side-comment-width: $nav-sidebar-width;
$image-snippet-width: 300px;
$task-sidebar-width: 320px;
$pane-width: 375px;
$nav-sidebar-large-width: $pane-width;
$modal-width: 500px;
$modal-lg-width: 720px;
$modal-steps-width: 960px;
$modal-steps-lg-width: 1200px;
$content-width: 960px;
$content-width-lg: 1165px;
$profile-full-padding: $imgsize-xl + ($content-padding-lg * 2);
$public-nav-height: $nav-search-height;
$intercom-space-bottom: 80px;
$intercom-space-right: 80px;
$profile-tabs-height: 34px; // update consts/positions navSearchHeight value as well
$card-height: 200px;

$desktop-max-width: 1328px;

// media queries
$mq-tiny: "(max-width: 399px)";
$mq-phablet: "(min-width: 400px) and (max-width: 767px)";
$mq-mobile: "(max-width: 767px)"; // tiny + phablet
$mq-tablet: "(min-width: 768px) and (max-width: 1023px)";
$mq-handheld: "(max-width: 1023px)"; // mobile + tablet
$mq-desktop: "(min-width: 1024px) and (max-width: 1679px)";
$mq-tablet-desktop: "(min-width: 768px) and (max-width: 1679px)"; // tablet + desktop
$mq-jumbo: "(min-width: 1680px)";
$mq-tablet-plus: "(min-width: 768px)"; // tablet + desktop + jumbo
$mq-desktop-plus: "(min-width: 1024px)"; // desktop + jumbo
$mq-portrait: "(orientation: portrait)";
$mq-landscape: "(orientation: landscape)";

// z-indexes
$z-notify: 11000;
$z-preloader: 10000;
$z-bottombar-tablet: 9900;
$z-modal: 9000;
$z-popup: 2000;
// photo-swipe:      1500;
$z-sidebar-toggle: 1000;
$z-topbar: 950;
$z-sidebar: 900;
$z-bottombar: 850;
$z-overlay: 800;
$z-actionbar: 500;
$z-floater: 100;
$z-above-content: 2;
$content-max-ems: 44;
$content-indent: $content-padding-lg;
$content-print-indent: 0.25in;
$content-print-body-size: 8pt;
$content-print-heading-size: 10pt;
$content-print-title-size: 24pt;

// insight content screen
$content-font-size: $fontsize-content;
$headline-font-size: $fontsize-xxl;
$caption-font-size: $fontsize-xs;
$content-line-height: $fontsize-content * $lineheight-content;
$full-content-margin: $content-line-height;
$half-content-margin: $full-content-margin * 0.5;
